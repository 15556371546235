var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{staticClass:"py-3 py-md-2",class:{ 'light-green lighten-5 green--text': hover },attrs:{"align":"center","no-gutters":""},on:{"click":function($event){return _vm.$refs.dettaglio.open()}}},[(_vm.$vuetify.breakpoint.width > 600)?_c('v-col',{staticClass:"d-flex justify-center",staticStyle:{"cursor":"pointer"},attrs:{"cols":"1"}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":_vm.avatar_url}})],1)],1):_vm._e(),_c('v-col',{staticClass:"text-truncate",staticStyle:{"cursor":"pointer"},attrs:{"cols":"5","md":"3"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.nome))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.tipo_vaso))])]),_c('v-col',{attrs:{"cols":"2","md":"1"}},[_vm._v(" Ø "+_vm._s(_vm.item.diametro)+" ")]),(_vm.$vuetify.breakpoint.width > 600)?_c('v-col',{attrs:{"cols":"3"}},[(_vm.item.isNew)?[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-spa")]),_c('span',{staticClass:"primary--text mr-3"},[_vm._v(" Novità")])]:_vm._e(),_vm._v(" "+_vm._s(_vm.item.info)+" ")],2):_vm._e(),_c('v-col',{attrs:{"cols":"2","md":"1"}},[_vm._v(" € "+_vm._s(_vm.item.prezzo))]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v("€ "+_vm._s(_vm.prezzo_unitario))]),_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{staticClass:"mr-4",attrs:{"color":"pink lighten-1","dark":"","value":_vm.numero_prodotti,"content":_vm.numero_prodotti,"overlap":"","offset-x":"20","offset-y":"20"}},[_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"color":"green","dark":"","small":"","fab":"","icon":""},on:{"click":function($event){_vm.show_dialog = true}}},on),[_c('v-icon',[_vm._v("mdi-cart")])],1)],1)]}}],null,true)},[_vm._v(" Acquista ")]),_c('DialogDettaglio',{ref:"dettaglio",attrs:{"item":_vm.item,"show":_vm.show_dialog},on:{"close":function($event){_vm.show_dialog = false},"deleted":function($event){return _vm.$emit('deleted')},"updated":function($event){return _vm.$emit('updated')}}}),(_vm.item.img_list.length)?_c('FotoGallery',{attrs:{"prodotto":_vm.item}}):_vm._e(),(_vm.item.preferito)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.switch_pref()}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-heart-outline")])],1)]}}],null,true)},[_vm._v(" Rimuovi da preferiti ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"icon":""},on:{"click":function($event){return _vm.switch_pref()}}},on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-heart-outline")])],1)]}}],null,true)},[_vm._v(" Aggiungi a preferiti ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }