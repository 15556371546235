//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SubCategList from "./listino_subcateg_desktop";
import listino_main_mixin from '../mixins/listino_main_mixin'
export default {
  props: {
    lista: Array,
  },
  components: {
    SubCategList,
  },
  mixins:[listino_main_mixin]
};
