//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProdottoListino from "./listino_prodotto_element_desktop";
export default {
  components: {
    ProdottoListino,
  },
  props: {
    sub_categ: Object,
    show: Boolean,
    not_first: Boolean
  },
  data() {
    return {
      show_inner: true,
    };
  },
  watch: {
    show: function () {
      this.show_inner = this.show;
    },
  },
};
