//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    prodotto: Object,
  },
  data() {
    return {
      dialog: false,
      dialog_enl: false,
      random_date: 0,
      image_open: "",
    };
  },
  computed: {
  },
  methods: {
    img_url(img){
      return process.env.VUE_APP_API_ROOT + "/prodotto/" + this.prodotto.id +"/foto/" + img.id + "?t=" + img.md5
    },
    open_img(img) {
      this.image_open = this.img_url(img);
      this.dialog_enl = true;
    },
  },
};
